.des {
    display: flex;
    align-items: center;
    gap: 6px;
}

.upload-progress {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;

    &__line {
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: var(--background-day-additional, #E4E6EA);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            border-radius: 2px;
            background: var(--action-day-main, #296EFF);
            height: 100%;
            width: var(--width-blue-line);
        }
    }

    &__percent {
        color: var(--foreground-day-main, #13141A);
        text-align: right;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        width: 32px;
    }
}

.progress-info {
    display: flex;
    align-items: center;

    gap: 8px;
    margin-bottom: 16px;


    &.failed {
        flex-wrap: wrap;
        margin-bottom: 32px;
        padding: 16px 0;
        border-bottom: 1px solid #E4E6EA;
        border-top: 1px solid #E4E6EA;

        ul {
            width: 100%;
            padding-left: 48px;

            li {
                list-style: disc;
                color: var(--foreground-day-main, #13141A);
                font-family: var(--subfont);
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;

                .grey {
                    color: var(--foreground-day-secondary, #868B96);
                }
            }
        }
    }
}

.tooltip-container {
    position: relative;
    cursor: default;
}


.tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 1;

}

.file-name__wrapper {
    display: flex;
    align-items: center;
}

.file-name {
    display: inline-block;
    max-width: 316px;
}