.album {
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0 !important;

    .albumInner {
        padding: 40px;
    }
}

.trackItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 100%;
    padding: 20px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #E4E6EA;
    }

    &>div {
        width: max-content;
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 20px;

        &>span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #868B96;
        }
    }

    &__image {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 4px;
    }

    &__name {
        p {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #13141A;
        }
    }

    &__feat {
        margin: auto 100px auto 100px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #868B96;
        display: flex;

        p {
            margin-left: 4px;
            color: #13141A;
        }
    }

    .moreActionsWrapper {
        margin: auto 0 auto auto;
    }
}