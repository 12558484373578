@import '../../../src/assets/styles/mixins';

.custom-multiselect {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__top {
    padding: 16px 16px 0 16px;
  }

  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__header {
    display: flex;
    padding: 25px;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center;
  }

  &__btn {
    border: 1px solid #E4E6EA;
    background-color: #fff;
    border-radius: 8px;
    padding: 14px 0 14px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    height: 48px;
    width: 100%;
    transition: all 0.3s;
    cursor: pointer;

    &.tags-value {
      //padding-left: 10px;

      .custom-multiselect__value {
        padding: 6px 6px 6px 16px;
      }
    }
  }

  &__placeholder {
    font-size: 14px;
    line-height: 20px;
    color: var(--primary);
    opacity: 0.5;
  }

  &__max-text {
    font-family: var(--subfont);
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #83868c;
    margin-top: 16px;
  }

  &__values-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: auto;
    width: calc(100% - 40px);

    &::-webkit-scrollbar {
      display: none;
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      display: none;
    }

    &.list-view {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  &__value {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: var(--background-day-additional, #E4E6EA);
    padding: 6px;
    min-width: fit-content;
    pointer-events: auto;
    cursor: default;

    &>span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #13141A;
      margin-right: 6px;
    }

    button {
      display: flex;
      z-index: 1;
      border-radius: 10px;

      &:hover {
        //background-color: lighten($color: #2f3445, $amount: 3);
      }
    }
  }

  &__single-value {
    font-family: var(--subfont);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray);
    padding-left: 6px;
  }

  &__halperText {
    font-family: var(--subfont);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #83868c;
    margin-top: 8px;
  }

  &__search-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #282a34;
    border: 1px solid #2f3445;
    border-radius: 8px;
  }

  &__search-field {
    width: 100%;

    &>div {
      max-width: unset;
    }
  }

  &__search-icon {
    opacity: 0.7;
    margin-right: 8px;
  }

  &__label-icon {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 6px;
  }

  &__arrow {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: all 0.3s;
    border-radius: 10px;
    z-index: 1;

    &.active {
      transform: scale(-1);
    }

    &:hover {
      background-color: rgba(237, 237, 237, 0.05);
    }
  }

  &__label {
    font-family: var(--font);
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #292e37;
    margin-bottom: 8px;
    margin-right: 12px;
  }

  &__search {
    height: 40px;
  }

  &__clear_all {
    color: var(--action-day-secondary, #1661FF);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &__dropdown {
    position: absolute;
    top: 51px;
    z-index: 2;
    background: #ffffff;
    border: 1px solid #E4E6EA;
    box-shadow: 0px 16px 32px rgba(19, 20, 26, 0.05);
    border-radius: 8px;
    width: 100%;
    overflow: hidden;

    &.tagSelect {
      .custom-multiselect__dropdown-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        max-height: 164px;
        padding: 0 16px;
        height: auto;
        margin-top: 16px;

        hr {
          margin-bottom: 12px;
        }
      }

      &.list {
        .custom-multiselect__dropdown-list {
          max-height: 380px;
        }
      }

      .custom-multiselect__item {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 16px;
        background: var(--background-day-additional, #E4E6EA);
        color: #13141A;
        border-radius: 18px;
        margin-right: 8px;
        margin-bottom: 12px;
        transition: all 0.3s;
        width: fit-content;

        &.list-view {
          width: 100%;
          gap: 4px;
          justify-content: flex-start;
          padding: 0;
          height: auto;
          background-color: transparent;
        }

        &.active-tag:not(.list-view) {
          background: var(--action-day-main, #296EFF);

          p {
            color: var(--foreground-day-white, #FFF);
          }
        }
      }

      .custom-multiselect__item-label {
        p {
          opacity: 0.7;
        }
      }

      .custom-multiselect__item-emoji {
        display: inline-block;
        margin-right: 6px;
        font-size: 18px;
        line-height: 20px;
      }

      .custom-multiselect__dropdown-shadow {
        top: 78%;
        height: 65px;
      }
    }
  }

  &__select-btn {
    width: 95vw;

    &-container {
      box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.04);
      margin: auto auto 0px;
      background: #fff;
      width: 100vw;
      padding: 16px;
    }
  }

  &__dropdown-list {
    max-height: 380px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-right: 8px;
    width: 100%;
    margin-top: 8px;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      -webkit-border-radius: 12px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: transparent;
      border-radius: 10px;
    }
  }

  &__dropdown-shadow {
    top: 92%;
    position: absolute;
    height: 44px;
    width: 100%;
    background: linear-gradient(180deg, rgba(40, 42, 52, 0) 0%, #282a34 100%);
    z-index: 3;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s;
    padding: 10px 16px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__single-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 16px 8px 16px;
    font-family: var(--subfont);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray);
    opacity: 0.7;

    &.selected {
      opacity: 1;
    }

    p {
      margin-right: 4px;
    }
  }

  &__item-img {
    margin-right: 8px;
    width: 36px;
    height: 36px;
  }

  &__item-label {
    display: flex;
    align-items: center;

    p {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: var(--subfont);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #13141A;
    }

    .light {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      font-family: var(--subfont);
      color: #83868c;
    }
  }

  &__icon {
    transition: all 0.3s;
    opacity: 0;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.3px solid #ededed;
    opacity: 0.7;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    transition: all 0.2s;

    &.active {
      background-color: #3d7cff;
      border-color: #3d7cff;
      opacity: 1;

      .custom-multiselect__icon {
        opacity: 1;
      }
    }
  }

  &.disable {
    opacity: 0.5;
  }

  &__add-block {
    display: flex;
    align-items: center;
    padding-left: 16px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray);
    margin: 10px 0;

    span {
      font-weight: 500;
      color: #83868C;
      display: inline-block;
      margin-right: 4px;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__add-btn {
    padding: 10px;
    background: #2F3445;
    border-radius: 18px;
    margin-right: 8px;
    transition: all 0.3s;

    &:hover {
      background: #3a4155;
    }
  }

  &.theme-dark {
    .custom-multiselect {
      &__btn {
        background-color: var(--darkSecondary);
        border-color: var(--darkSecondary);

        &:hover {
          border-color: #2F3445;
        }
      }

      &__label {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: var(--gray);
      }

      &__dropdown {
        background-color: var(--darkSecondary);

        &.tagSelect {
          .custom-multiselect__item {
            background-color: #2f3445;

            &:hover {
              background-color: lighten($color: #2f3445, $amount: 2);
              opacity: 1;
            }

            &.active-tag {
              opacity: 1;
              background-color: #3d7cff;

              p {
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
      }

      &__item-label {
        p {
          color: var(--gray);
        }
      }

      &__item,
      &__single-item {
        background-color: var(--darkSecondary);

        &:hover {
          background-color: lighten($color: #282a34, $amount: 1.5);
        }

        &:active {
          background-color: lighten($color: #282a34, $amount: 3);
        }
      }

      &__search-field {
        color: var(--gray);
        background-color: transparent;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

.clear_search_input {
  display: flex;
  padding: 5px 10px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
  }
}