.root {
  width: 256px;
  background-color: var(--nav-background);
  padding: 8px 24px;

  .logo {
    margin-left: 6px;
    width: 128px;
  }

  .navList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 100px;

    .navItem {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 16px;
      border-radius: 8px;

      svg {
        &:nth-child(1) {
          display: block;
        }

        &:nth-child(2) {
          display: none;
        }
      }

      span {
        font-family: var(--font);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #13141A;
        opacity: 0.7;
      }

      &.active {
        background-color: #f8f8fa;

        svg {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: block;
          }
        }

        span {
          opacity: 1;
        }
      }
    }
  }
}