.dialog-mui {
  &__paper {
    min-width: 480px;
    color: inherit;
    border-radius: 5px;
    box-shadow: none;
    background-color: #F8F8FA;
  }

  &__content {
    position: relative;
    overflow: auto;
    padding: 40px;
    max-height: 850px;
    min-height: 100%;

    &.with-title {
      padding-top: 50px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--light);
      border-radius: 2px;
      -webkit-border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 5px;
      -webkit-border-radius: 5px;

      &:window-inactive {
        background-color: var(--secondary);
      }
    }
  }

  &__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    width: 32px;
    height: 32px;
    z-index: 10;

    path {
      transition: 0.3s ease;
    }

    &:hover {
      path {
        opacity: 1;
        fill: var(--error);
      }
    }
  }

  &__dialog-title {
    position: absolute;
    top: 12px;
  }

  &__close-icon {
    margin: auto;
  }

  &__title {
    color: var(--foreground-day-main, #13141A);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  }

  &__des {
    color: var(--foreground-day-main, #13141A);
    font-family: var(--subfont);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .grey {
      color: var(--foreground-day-secondary, #868B96);
    }

  }

  &.side-bar {
    .MuiDialog-scrollPaper {
      justify-content: flex-end;

      .dialog-mui {
        &__paper {
          margin: 0;
          border-radius: 0;
          height: 100vh;
          max-height: unset;
          max-width: unset;
          min-width: unset;
          min-height: unset;

          width: 480px;

          transform: translateX(100%);
          transition: transform 500ms;
        }

        &__content {
          padding: 100px 40px;
          max-height: unset;

          &.with-title {
            padding-top: 128px;
          }

        }

        &__close-button {
          top: 48px;
          right: 40px;
        }

        &__dialog-title {
          top: 48px;
          left: 40px;
        }
      }
    }

    &.visible {
      .MuiDialog-scrollPaper {
        justify-content: flex-end;

        .dialog-mui {
          &__paper {
            transform: translateX(0);
          }
        }
      }
    }
  }

  .MuiDialog-paperWidthMd {
    width: 720px;
  }
}