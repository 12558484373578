@import 'src/assets/styles/mixins';

.select-component {
  display: inline-flex;
  min-width: 246px;

  .select {
    &__control {
      width: 100%;
      height: 40px;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary);
      background-color: var(--white);
      border: 1px solid var(--gray);
      border-radius: 8px;
      cursor: pointer;

      &--is-focused,
      &:hover {
        border-color: var(--info);
        box-shadow: none;
      }

      &--is-disabled {
        background-color: var(--light);
        border-color: var(--light);
      }

      /* &--menu-is-open {} */
    }

    &__value-container {
      padding: 2px 0 2px 15px;
      cursor: pointer;
    }

    &__single-value {
      margin-left: 0;
      margin-right: 0;
      width: -webkit-fill-available;
      font-family: var(--subfont);

      .status-label-wrapper {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #13141A;

        span {
          opacity: 0.5;
        }
      }
    }

    &__placeholder {
      margin-left: 0;
      margin-right: 0;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary);
      opacity: 0.5;
    }

    &__indicator {
      cursor: pointer;
    }

    &__indicator-separator {
      display: none;
    }

    // Menu
    &__menu {
      z-index: 2;
      margin-top: 4px;
      border: 1px solid #E4E6EA;
      box-shadow: 0px 16px 32px rgba(19, 20, 26, 0.05);
      border-radius: 8px;
    }

    &__menu-list {
      padding-top: 5px;
      padding-bottom: 5px;
      @include scrollbar(5px, var(--primary), var(--light), 5px, 2px);
    }

    &__option {
      font-family: var(--subfont);
      position: relative;
      padding: 14px 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #13141A;
      opacity: 0.7;
      cursor: pointer;

      &--is-focused {
        opacity: 1;
        background-color: transparent;
        /* font-weight: 600; 
        color: var(--primary);
        background-color: var(--light); */
      }

      &--is-selected {
        opacity: 1;
        background-color: transparent;
        /* font-weight: 800;
        color: var(--primary);
        background-color: var(--white); */

        &:after {
          content: none;
        }
      }
    }
  }

  &.big {
    .select {
      &__control {
        height: 48px;
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  .css-b8ldur-Input {
    margin: 0;
  }

  .select-indicator {
    width: 24px;
    height: 24px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .indicator-active {
    transform: scale(-1);
  }
}

// Searchable select
.select-component--searchable {
  .select {

    &__control,
    &__value-container {
      cursor: text;
    }
  }
}

// With search icon
.select-component--search-icon {
  .select {
    &__value-container {
      padding-left: 55px;

      &:before {
        content: url('./icons/search.svg');
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }
    }

    &__dropdown-indicator {
      display: none;
    }
  }
}