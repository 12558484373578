:root {
  --font: 'Gilroy', sans-serif;
  --subfont: 'Inter', sans-serif;

  --primary: #204569;
  --secondary: #0a2540;
  --error: #e44b4b;
  --warning: #ffae00;
  --info: #1661FF;
  --success: #2cc197;
  --light: #ebf3fa;
  --dark: #212529;

  --white: #ffffff;
  --black: #000000;
  --gray: #E4E6EA;

  --auth-background: #F1F2F3;
  --nav-background: #EBECEE;
  --contained-btn: #296EFF;

  --container: 1390px;
  --small-container: 890px;
  --transition-duration: 0.3s;
}