.root {
  z-index: 1;
  background-color: var(--nav-background);
  border-left: 2px solid var(--auth-background);

  .container {
    display: flex;
    align-items: stretch;
    height: 72px;
  }

  .globalBackAction {
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    svg {
      transition: transform 150ms;
      margin-top: -2px;
    }

    &:hover {
      svg {
        transform: translateX(-2px);
      }
    }
  }

  .logout {
    margin-right: -4px;
    padding: 4px;
    width: 32px;
    height: 32px;

    path {
      transition: stroke var(--transition-duration);
    }

    &:hover path {
      stroke: var(--error);
    }
  }
}

.user {
  margin-left: auto;
  margin-right: 10px;
  text-align: right;

  .userMail {
    font-family: var(--subfont);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #13141A;
  }
}