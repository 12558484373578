@import 'src/assets/styles/mixins';


.nameWrapper {
  img {
    border-radius: 50%;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: calc(100% - 36px - 8px);

    p {
      display: inline-block;
    }

    span {
      max-width: calc(100% - 12px - 4px);
    }

    svg {
      min-width: 12px;
      margin: 0 0 -2px 4px;
    }
  }
}

.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 20px 0;

  &~* {
    display: none !important;
  }
}