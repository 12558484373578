.campaignAudio {
    display: flex;
    border-radius: 8px;
    background: #F8F8FA;
    height: auto;
    max-height: unset;

    &:not(.loaded) {
        min-height: 200px;
    }

    .inputStylesBlock {
        border-color: #F8F8FA;
        position: relative;
        height: auto;
    }

    .noAudio {
        display: flex;
        gap: 16px;
        align-items: center;

        svg {
            margin: 0;
        }

        div {
            p {
                color: #13141A;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
            }

            span {
                color: #868B96;
                font-family: var(--subfont);
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;

                span {
                    color: #1661FF;
                }
            }
        }
    }

    .audio {
        display: flex;
        width: 100%;
        flex-direction: column;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .count {
                color: #13141A;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;

                span {
                    color: #83868C;
                    font-weight: 500;
                    margin-left: 4px;
                }
            }

            .addAudio {
                position: relative;
                margin-right: -16px;

                svg {
                    margin: -2px 4px 0 0;
                }
            }
        }

        .audioList {
            display: flex;
            flex-direction: column;

            &Item {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 18px 0;

                &:not(:last-child) {
                    border-bottom: 1px solid #E4E6EA;
                }

                .info {
                    p {
                        color: #13141A;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        margin: 0;

                        &>svg {
                            margin: 0 0 -2px 8px;
                        }
                    }

                    span {
                        color: #868B96;
                        font-family: var(--subfont);
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }

                .statistic {
                    display: flex;
                    gap: 16px;

                    &>div {
                        display: flex;
                        gap: 4px;
                        align-items: center;
                        color: #13141A;
                        font-family: var(--subfont);
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 16px;

                        svg {
                            margin: 0;
                        }
                    }

                    .moreActionsWrapper {
                        display: flex;

                        .moreActions {
                            &__btn {
                                position: relative;
                            }
                        }
                    }
                }

                .description {
                    display: block;
                    width: 100%;
                    margin-top: 12px;
                    color: #868B96;
                    font-family: var(--subfont);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;

                    &Inner {
                        max-height: 32px;
                        overflow: hidden;

                        &Wrapper {
                            display: block;
                        }
                    }

                    &Button {
                        margin-top: 4px;
                        margin-left: -16px;

                        svg {
                            margin: 0 0 0 8px;
                            transform: rotate(-90deg);
                        }
                    }

                    &.opened {
                        .descriptionInner {
                            max-height: unset;
                        }

                        .descriptionButton {
                            svg {
                                transform: rotate(90deg);
                            }
                        }
                    }

                    &:not(.openable) {
                        .descriptionButton {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.editModalWrap {
    display: flex;

    .desc {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .inputLabel {
        &__wrapper {
            :global(.input-mui) {
                width: 100%;

                &__root {
                    width: 100%;
                    max-width: unset;
                }
            }
        }
    }
}