.button-mui {
  padding: 6px 16px;
  min-width: 160px;
  height: 48px;
  font-family: inherit;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: normal;
  border-radius: 8px;
  box-shadow: none;
  text-transform: unset;

  &:hover {
    opacity: 1;
  }

  &:disabled {}

  // variant='contained'
  &--contained {
    color: var(--white);
    background-color: var(--contained-btn);

    &:hover {
      background-color: darken(#3796f6, 10%);
    }

    &-primary {
      background: var(--background-day-additional, #E4E6EA);
      color: var(--foreground-day-main, #13141A);

      &:hover {
        background: var(--background-day-additional, #E4E6EA);
      }
    }

    &-secondary {
      background-color: var(--error);

      &:hover {
        background-color: darken(#e44b4b, 10%);
      }
    }
  }

  // variant='outlined'
  &--outlined {
    color: var(--info);
    border-color: var(--info);

    &:hover {
      background-color: lighten(#3796f6, 35%);
    }

    &-primary {
      color: var(--primary);
      border-color: var(--primary);

      &:hover {
        background-color: lighten(#204569, 60%);
      }
    }

    &-secondary {
      color: var(--error);
      border-color: var(--error);

      &:hover {
        background-color: lighten(#e44b4b, 35%);
      }
    }
  }

  // variant='text'
  &--text {
    display: flex;
    color: var(--info);
    height: 32px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    min-width: unset;
    padding: 0;

    svg {
      margin-right: 6px;
    }

    &:hover {
      background-color: transparent;
    }

    &-primary {
      color: var(--primary);

      &:hover {
        background-color: lighten(#204569, 60%);
      }
    }

    &-secondary {
      color: var(--error);

      &:hover {
        background-color: lighten(#e44b4b, 35%);
      }
    }
  }

  // size='small'
  &--small {
    min-width: 120px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    // size='Xsmall'
    &.xsmall {
      min-width: 76px;
      height: 32px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }

    svg {
      margin-right: 6px;
    }
  }

  // size='large'
  &--large {
    min-width: 168px;
    height: 56px;
  }

  // startIcon & endIcon max size
  &__start-icon svg,
  &__end-icon svg {
    max-width: 20px;
    max-height: 20px;
  }
}