.feed {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &Item {
        border-radius: 8px;
        background: var(--background-day-secondary, #F8F8FA);
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        position: relative;

        &>.feedItem {
            padding: 12px 0 0;
        }

        &>.bordered {
            border-radius: 6px;
            background: var(--background-day-main, #EBECEE);
            padding: 12px;

            &>.feedItem {
                border-radius: inherit;
                background: inherit;
                padding: inherit;
            }
        }
    }

    &Reposted {
        display: flex;
        gap: 8px;

        p {
            color: var(--foreground-day-main, #13141A);
            font-size: 12px;
            line-height: 16px;

            b {
                font-weight: 600;
            }

            span {
                color: var(--foreground-day-secondary, #868B96);
                font-weight: 500;
                margin-left: 4px;
            }
        }
    }

    &User {
        display: flex;
        gap: 8px;
        max-width: calc(100% - 80px);

        &>img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
        }

        &>div {
            display: flex;
            flex-direction: column;

            p {
                color: var(--foreground-day-main, #13141A);
                font-size: 14px;
                line-height: 20px;

                &>* {
                    display: inline;
                }

                b {
                    font-weight: 600;
                }

                span {
                    color: var(--foreground-day-secondary, #868B96);
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    margin-left: 4px;
                }
            }

            &>span {
                color: var(--foreground-day-secondary, #868B96);
                font-family: var(--subfont);
                font-size: 10px;
                font-weight: 500;
                line-height: 16px;
            }
        }
    }

    &Text {
        color: var(--foreground-day-main, #13141A);
        font-family: var(--subfont);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &Inner {
            max-height: 60px;
            overflow: hidden;

            &Wrapper {
                display: block;
            }
        }

        &Button {
            margin-top: 4px;
            margin-left: -16px;

            svg {
                margin: 0 0 0 8px;
                transform: rotate(-90deg);
            }
        }

        &.opened {
            .feedTextInner {
                max-height: unset;
            }

            .feedTextButton {
                svg {
                    transform: rotate(90deg);
                }
            }
        }

        &:not(.openable) {
            .feedTextButton {
                display: none;
            }
        }
    }

    &Images {
        overflow: hidden;
        border-radius: 8px;
        display: grid;
        gap: 4px 4px;
        width: 100%;
        height: 522px;
        margin-top: 12px;

        &1 {
            grid-template-columns: repeat(1, 1fr);

            img {
                aspect-ratio: 16/9;
            }
        }

        &2 {
            grid-template-columns: repeat(2, 1fr);
            aspect-ratio: 4/3;
        }

        &3 {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
                'A B'
                'A C';

            img {
                &:first-child {
                    height: 100%;
                    object-position: center;
                }

                &:nth-child(2) {
                    height: 259px;
                }

                &:nth-child(3) {
                    height: 259px;
                }
            }
        }

        &4 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas:
                'A A A'
                'B C D';

            img {
                &:first-child {
                    height: 100%;
                    max-height: 346px;
                    object-position: center;
                }

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    height: 172px;
                }
            }
        }

        &5 {
            grid-template-columns: repeat(6, 1fr);
            grid-template-areas:
                'A A A B B B'
                'C C D D E E';

            img {

                &:first-child,
                &:nth-child(2) {
                    height: 100%;
                    max-height: 346px;
                    object-position: center;
                }

                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    height: 172px;
                }
            }
        }

        &6 {
            grid-template-columns: repeat(8, 1fr);
            grid-template-areas:
                'A A A A B B B B'
                'C C D D E E F F';

            img {

                &:first-child,
                &:nth-child(2) {
                    height: 100%;
                    max-height: 346px;
                    object-position: center;
                }

                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    height: 172px;
                }
            }
        }

        &7 {
            grid-template-columns: repeat(12, 1fr);
            grid-template-areas:
                'A A A A B B B B C C C C'
                'D D D E E E F F F G G G';

            img {

                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                    height: 100%;
                    max-height: 346px;
                    object-position: center;
                }

                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    height: 172px;
                }
            }
        }

        img {
            cursor: pointer;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        &Template {
            &:first-child {
                grid-area: A;
            }

            &:nth-child(2) {
                grid-area: B;
            }

            &:nth-child(3) {
                grid-area: C;
            }

            &:nth-child(4) {
                grid-area: D;
            }

            &:nth-child(5) {
                grid-area: E;
            }

            &:nth-child(6) {
                grid-area: F;
            }

            &:nth-child(7) {
                grid-area: G;
            }
        }
    }

    &Tracks {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &Location {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--foreground-day-secondary, #868B96);
        font-family: var(--subfont);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &PostStatistic {
        display: flex;
        gap: 8px;
        padding-top: 12px;

        &Button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            padding: 4px 8px;
            min-width: 54px;

            span {
                color: var(--foreground-day-main, #13141A);
                font-family: var(--subfont);
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
            }

            &.blue {
                svg path {
                    stroke: var(--action-day-secondary, #1661FF);
                }

                span {
                    color: var(--action-day-secondary, #1661FF);
                }
            }
        }
    }
}

.image_swiper {
    width: 50%;
    position: relative;

    .slide {
        .post__image {
            width: 100%;
            height: 100%;

            &>img,
            &>div {
                width: 100%;
                height: 100%;


                &:first-child {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    filter: blur(2.5px);
                }

                &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: lightgray;
                    opacity: 0.5;
                }

                &:last-child {
                    object-fit: contain;
                    position: relative;
                }
            }
        }
    }

    &__navigation {
        position: absolute;
        top: 50%;
        z-index: 2;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;

        button {
            padding: 15px;

            background: rgba(131, 134, 140, 0.2);
            backdrop-filter: blur(2px);
            border-radius: 24px;

            &:global(.swiper-button-disabled) {
                display: none;
            }

            &:hover {
                background: rgba(131, 134, 140, 1);
            }
        }
    }

    &__prev {
        transform: rotate(180deg);
    }

    &__next {
        margin-left: auto;
    }
}

.moreActionsWrapper {
    position: absolute;
    top: 24px;
    right: 24px;
}

.modalWrapper {
    padding: 0;
    display: flex;
    background: var(--background-day-secondary, #F8F8FA);

    &Pepper {
        border-radius: 0;
        background-color: transparent;
        max-width: 75vw;
        min-width: calc(75vw / 2);
        border-radius: 8px;
    }

    &Images {
        display: flex;
    }

    &Post {
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    &Images,
    &Post {
        flex-grow: 1;
        flex-basis: 50%;
    }

    &Comments {
        margin-top: 24px;
        padding-top: 24px;
        padding-bottom: 32px;
        border-top: 1px solid #E4E6EA;
    }

    .feed {
        &Item {
            border-radius: 0;
            padding: 0;

            &>.feedItem {
                padding: 12px 0 0;
            }

            &>.bordered {
                border-radius: 6px;
                background: var(--background-day-main, #EBECEE);
                padding: 12px;

                &>.feedItem {
                    border-radius: inherit;
                    background: inherit;
                    padding: inherit;
                }
            }

            .moreActionsWrapper {
                position: absolute;
                top: -12px;
                right: 30px;
            }
        }
    }
}