@import 'src/assets/styles/mixins';

.root {
  @include scrollbar(8px, var(--info), var(--light), 5px, 2px);
  margin-top: 24px;
}

.table {
  width: 100%;
  color: var(--primary);
  border-collapse: collapse;
  border-spacing: 0;

  &Fixed {
    table-layout: fixed;
  }
}

.head {
  display: table-header-group;
}

.body {
  display: table-row-group;
}

.row {
  display: table-row;
  border-bottom: 1px solid #E4E6EA;

  &Selected {
    background-color: var(--light);
  }
}

.header,
.data {
  padding: 8px 12px;
  vertical-align: middle;

  &:not(.withExpand) {

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.withExpand {
  :global(.skeleton-mui) {
    width: 100%;
  }
}

.header {
  height: 45px;
  line-height: 1;
  color: var(--info);
  border-bottom: 1px solid var(--info-light);

  &Inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &Name {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #868B96;
  }
}

.data {
  height: 60px;
  //border-bottom: 1px solid var(--light);
}

.sortBtn {
  display: inline-flex;
  align-items: center;
  gap: 2px;

  &.up path:first-child {
    fill: #868B96;
  }

  &.down path:last-child {
    fill: #868B96;
  }
}

.sortIcon {
  flex-shrink: 0;
  fill: var(--info-light);
}