.trackSidebar {
    width: 360px;
    background: #EBECEE;
    border-top: 2px solid #F1F2F3;
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .cell {
        background: #F8F8FA;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &>div {
            display: flex;
            align-content: center;
            align-items: center;

            &:first-child {
                gap: 8px;
            }

            &:last-child {
                justify-content: space-between;
            }

            &>span {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #13141A;
            }

            &>p {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: #13141A;
            }
        }

        button {
            padding-right: 0;

            svg {
                transform: scale(-1);
                margin-left: 6px;
                transition: transform 150ms;
            }

            &:hover {
                svg {
                    transform: scale(-1) translateX(-2px);
                }
            }
        }

        &:nth-child(3n+1) {
            width: 100%;
        }

        &:not(:nth-child(3n+1)) {
            width: calc(50% - 8px);
        }
    }
}

.commentsDialogWrapper {
    padding: 24px;

    .commentsDialog {
        display: flex;
        flex-direction: column;

        .heading {
            display: flex;
            gap: 12px;
            border-bottom: 1px solid #E4E6EA;
            padding-bottom: 24px;
            margin-bottom: 24px;

            img {
                width: 56px;
                height: 56px;
                border-radius: 4px;
                object-fit: cover;
            }

            .audioInfo {
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #13141A;
                }
            }
        }
    }
}

.commentsList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
    padding-bottom: 48px;

    &__item {
        display: flex;
        gap: 12px;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-direction: row;
        margin-top: 16px;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
        }

        &>.moreActionsWrapper {
            opacity: 0;
            transition: opacity 150ms;
        }

        &:hover {
            &>.moreActionsWrapper {
                opacity: 1;
            }
        }

        &_content {
            display: flex;
            flex-direction: column;
            width: calc(100% - 36px - 32px - (12px * 2));

            p {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #13141A;

                span {
                    font-family: var(--subfont);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #868B96;
                    margin-left: 4px;
                }

                &.text {
                    font-family: var(--subfont);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #13141A;
                    margin-top: 4px;
                }
            }

            button {
                display: flex;
                width: max-content;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #868B96;
                margin-top: 12px;

                &::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 1px;
                    background-color: #868B96;
                }
            }
        }
    }
}