@import "src/assets/styles/mixins";

.finances {
  &Inner {
    .inner-wrap {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .smallHeading {
        color: var(--foreground-day-main, #13141a);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;

        .search {
          width: 360px;
          margin: 0 auto 0 0;

          &>div {
            max-width: unset;
          }
        }

        .lastPayment {
          color: var(--foreground-day-main, #13141A);
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }

        &>a {
          margin-left: -16px;
        }

        .paymentState {
          display: flex;
          gap: 16px;
          align-items: center;

          button {
            &>span:first-child {
              gap: 4px;
            }
          }
        }
      }

      &.history {
        padding-top: 16px !important;
      }
    }
  }
}

.availableTime {
  display: flex;
  gap: 8px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    span {
      color: var(--foreground-day-secondary, #868b96);
      font-family: var(--subfont);
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    p {
      color: var(--foreground-day-main, #13141a);
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      opacity: 0.7;
    }
  }
}

.paymentBlock {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--background-day-inputs, #fff);
  padding: 24px;

  &Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    span {
      color: var(--foreground-day-secondary, #868b96);
      font-family: var(--subfont);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &Modal {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 32px;

    h1 {
      margin-bottom: -16px;
    }

    &>span {
      color: var(--foreground-day-secondary, #868b96);
      font-family: var(--subfont);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .buttons {
      display: flex;
      gap: 16px;

      button {
        padding-left: 24px;
        padding-right: 24px;

        &.calculateBtn:disabled {
          color: white;
          opacity: 0.5;
          background: var(--action-day-main, #296eff);
        }
      }
    }
  }

  .calculateBtn {
    padding-left: 24px;
    padding-right: 24px;

    &:disabled {
      background: var(--action-day-main, #296eff);
    }
  }

  .calculating {
    display: flex;
    gap: 16px;
  }

  &.isCalculating {
    padding: 72px;
    justify-content: center;
  }

  .loader {
    width: 100%;
  }
}

.tableRoot {
  position: relative;

  &.inProgress {
    opacity: 0.5;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.noMargin {
    margin: 0;
  }

  .data {
    padding-top: 18px;
    padding-bottom: 18px;

    &:last-child {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .head {
    .row {
      border-bottom: none;
    }
  }

  .total {
    &Wrapper {
      display: table-header-group;
    }

    &Data {
      padding-top: 16px;
      padding-bottom: 12px;
      background: rgba(41, 110, 255, 0.05);

      &:first-child {
        padding-left: 44px;
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }

      p {
        color: var(--foreground-day-main, #13141A);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    &Plan {
      display: flex;
      flex-direction: column;
      justify-content: center;

      div {
        display: flex;
        gap: 4px;
        align-items: center;

        svg {
          width: 12px;
          height: 12px;
        }

        span {
          color: var(--foreground-day-secondary, #868B96);
          font-family: var(--subfont);
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }

  .amount {
    display: flex;
    align-items: center;
    gap: 8px;

    &Inner {
      display: inherit;
      align-items: center;
      gap: 8px;
      padding: 6px 12px;
      border-radius: 16px;
      background: rgba(41, 110, 255, 0.1);
      color: var(--foreground-day-main, #13141a);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &.clickable {
        cursor: pointer;
      }
    }

    &Input {
      width: 112px;

      .endAdornment {
        padding: 0 12px 0 2px;
        color: var(--foreground-day-main, #13141A);
        font-family: var(--subfont);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        opacity: 0.7;
      }
    }

    &Accept {
      cursor: pointer;
    }
  }

  .listen {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      color: var(--foreground-day-main, #13141A);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      span {
        color: var(--foreground-day-secondary, #868B96);
      }
    }
  }
}