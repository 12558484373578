.heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;

    &>.imageWrapper {
        position: relative;
        width: 128px;
        height: 128px;

        img {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.withPrice::after {
            content: '$' attr(data-price);
            position: absolute;
            display: block;
            top: -6px;
            right: -6px;
            z-index: 500;
            background: #E4E6EA;
            border: 4px solid #F1F2F3;
            border-radius: 15px;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #1661FF;
            padding: 4px 8px;
            height: 28px;
        }
    }

    &>.shortInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 20px - 128px);

        .lowerBlock {
            display: flex;
            justify-content: space-between;

            .artistBlock {
                display: flex;
                flex-direction: column;
                gap: 6px;
                max-width: calc(100% - 250px);
                width: 100%;
            }

            .moreActionsWrapper {
                display: flex;
                gap: 16px;

                .block {
                    background-color: #E4E6EA;
                    color: #13141A;
                }

                &>a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    background: #E4E6EA;
                    border-radius: 8px;
                }
            }
        }
    }

    .albumDesc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        width: 100%;

        .textWrap {
            overflow: hidden;
            max-height: calc(20px*3);

            &.visible {
                max-height: unset;
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #13141A;
            }
        }

        button {
            transform: translateX(-16px);

            svg {
                margin-left: 8px;
                transform: rotate(-90deg);
            }
        }
    }

    .blockReason {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 24px;
        gap: 16px;
        background: #F8F8FA;
        border-radius: 8px;
        align-items: center;
        margin-top: 20px;

        div {
            display: flex;
            flex-direction: column;
            width: calc(100% - 8px - 72px);
            gap: 4px;

            p {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #13141A;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #868B96;
                overflow-wrap: anywhere;
            }
        }
    }
}