.sidebar {
    width: 360px;
    background: #EBECEE;
    border-top: 2px solid #F1F2F3;
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .cell {
        background: #F8F8FA;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        &.half {
            width: calc(50% - 8px);
        }

        &Heading {
            color: var(--foreground-day-main, #13141A);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            &.withMargin {
                margin-top: 16px;
            }
        }

        &>div {
            display: flex;
            align-content: center;
            align-items: center;

            &:first-child {
                gap: 8px;
            }

            &:last-child:not(:first-child) {
                justify-content: space-between;
            }

            &>span {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #13141A;
            }

            &>p {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: #13141A;
            }
        }

        &>span {
            color: var(--foreground-day-secondary, #868B96);
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
        }

        &.balance {
            background: linear-gradient(261deg, rgba(136, 112, 255, 0.10) 0%, rgba(61, 124, 255, 0.10) 100%);
        }
    }
}