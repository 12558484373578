.checkbox-mui {
  padding: 0;
  color: var(--info);

  &--checked {

    .checkbox_filled_icon,
    .checkbox_filled_icon * {
      fill: var(--info);
      stroke: var(--info);
    }
  }

  &--disabled {}

  &--indeterminate {}

  &--padding {
    padding: 8px;
  }

  &.checkbox-mui-color {
    &--primary {
      color: var(--primary);
    }

    &--secondary {
      color: var(--error);
    }
  }
}