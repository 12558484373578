.tooltip-mui {
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  background: #13141A;
  border-radius: 8px;
  padding: 8px 12px;

  &--error-color {
    background-color: var(--error);

    .tooltip-mui__arrow {
      color: var(--error);
    }
  }

  &__arrow {
    color: #13141A;
  }
}

.MuiTooltip-popper {
  top: 4px !important;
}