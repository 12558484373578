@import 'src/assets/styles/mixins';

.advertising {

  &Inner {
    .tabWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tabs {
        display: flex;

        &>div {
          margin: 0;
          display: flex;
          gap: 8px;

          &>button {
            padding: 6px 16px;
            background: #EBECEE;
            color: rgba(19, 20, 26, 0.7);
            border-radius: 16px;
            margin: inherit;

            &:global(.btnActive) {
              color: #FFFFFF;
              background: #296EFF;
            }

            &::after,
            &::before {
              display: none;
            }
          }
        }
      }
    }

    .inner-wrap {
      .filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;

        .search {
          width: 360px;
          margin: 0 auto 0 0;

          &>div {
            max-width: unset;
          }
        }

        .moreFiltersButton {
          margin: 0 0 0 auto;
        }
      }
    }
  }

  &Edit {
    width: calc(744px + 80px);
    margin: auto;

    .inner-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      width: 100%;

      &.loading {
        min-height: 488px;
        align-items: center;
        justify-content: center;

        :global(.MuiCircularProgress-root) {
          color: var(--info);
        }
      }

      .inputLabel {

        &__blockTitle,
        &__blockDesc {
          width: 100%;
        }

        &__blockTitle {
          color: #13141A;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

        &__blockDesc {
          color: #868B96;
          font-family: var(--subfont);
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
        }

        &__wrapper {
          flex-direction: column;

          :global(.input-mui) {
            width: 100%;

            :global(.input-mui__root) {
              width: 100%;
              max-width: unset;
            }
          }
        }

        &.full {
          width: 100%;
        }

        &.half {
          width: calc(50% - 12px);
        }

        &.third {
          width: calc(33.333% - 16px);
        }
      }
    }

    .advertisingInner {
      align-items: flex-start;
    }
  }

  .sepatrator {
    width: 100%;
    height: 1px;
    background-color: #E4E6EA;
  }
}

.addNewButton {
  padding-right: 25px;
  padding-left: 25px;

  .sign {
    margin-right: 8px;
    margin-bottom: -2px;
    font-size: 20px;
    line-height: 20px;
  }
}

.nameWrapper {
  .info {
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #13141A;
    }

    .tooltip {
      margin-left: 8px;
    }
  }
}

.moreActionsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;

  a {
    display: flex;
    align-items: center;
  }

  .moreActions {
    position: relative;

    &__btn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      width: 32px;
      padding: 8px;
      cursor: pointer;
      border-radius: 16px;

      span {
        display: block;
        background: #13141A;
        width: 3px;
        height: 3px;
        border-radius: 50%;
      }

      &.active {
        background: #FFFFFF;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: calc(100% + 8px);
      right: -8px;
      gap: 20px;
      padding: 20px;
      background: #FFFFFF;
      border: 1px solid #E4E6EA;
      box-shadow: 0px 16px 32px rgba(19, 20, 26, 0.05);
      border-radius: 8px;
      z-index: 300;
      width: max-content;

      .actionButtons {
        display: flex;
        gap: 8px;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #13141A;

        &.deleteAction {
          color: #FB3254;
        }
      }
    }
  }
}

.endAdornment {
  padding: 0 16px;
}

.advertisingInnerHeading {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;

  &__generalInfo {
    span {
      font-family: var(--subfont);
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #868B96;
      margin-top: 4px;
    }
  }
}

.campaigns {
  /* background-color: #F8F8FA;
  padding: 24px;
  border-radius: 8px; */

  &__count {
    color: #83868C;
    font-weight: 500;
    margin-left: 4px;
  }

  &__mapper {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    &>h3 {
      width: auto;
    }

    &>button {
      margin: -6px -16px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    display: flex;
    gap: 8px;

    &>img {
      width: 36px;
      height: 36px;
      object-fit: cover;
    }

    &>div {
      display: flex;
      flex-direction: column;

      p {
        font-size: 14px;
        color: #13141A;
        font-weight: 600;
        line-height: 20px;

        .tooltip {
          margin: 0 0 -4px 8px;
        }
      }

      span {
        font-family: var(--subfont);
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        color: #868B96;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 40px;
  }

  &__status {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 6px 16px 6px 12px;

    &.true {
      color: #868B96;
      background-color: #EBECEE;
    }

    &.false {
      color: #2E9F69;
      background-color: rgba(46, 159, 105, 0.10);
    }
  }

  &__desc {
    font-family: var(--subfont);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #868B96;
  }
}

.hiddenFileInputLabel {
  width: 100%;
  height: 200px;
  position: relative;

  &>.inputStylesBlock,
  &>input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input {
    opacity: 0;
  }

  .inputStylesBlock {
    border: 2px dashed #E4E6EA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 500ms;

    svg {
      margin-bottom: 16px;
      transition: all 250ms;
    }

    p {
      margin-bottom: 4px;
    }

    &.hover {
      border-color: var(--button);

      svg {
        transform: scale(1.1);
      }
    }

    &:not(.hover) {
      z-index: 50;
    }

    &.loading {
      :global(.circular-loader-mui) {
        position: absolute;
        background-color: transparent;
      }
    }

    &.loaded {
      padding: 24px;

      p {
        position: relative;
        z-index: 999;
      }

      img {
        height: 100%;
      }

      &>button {
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 999;
      }
    }
  }
}