.sidebar {
    width: 360px;
    background: #EBECEE;
    border-top: 2px solid #F1F2F3;
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .cell {
        background: #F8F8FA;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        &>div {
            display: flex;
            align-content: center;
            align-items: center;

            &:first-child {
                gap: 8px;
            }

            &:last-child {
                justify-content: space-between;
            }

            &>span {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #13141A;
            }

            &>p {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: #13141A;
            }
        }
    }
}