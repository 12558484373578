.root {
  display: flex;
  height: 100vh;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    background-color: var(--auth-background);
    justify-content: space-evenly;

    .hidden {
      opacity: 0;
    }
  }
}
