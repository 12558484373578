.heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 40px;

    &>.imageWrapper {
        position: relative;
        width: 72px;
        height: 72px;

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.withPrice::after {
            content: '$' attr(data-price);
            position: absolute;
            display: block;
            top: -6px;
            right: -6px;
            z-index: 500;
            background: #E4E6EA;
            border: 4px solid #F1F2F3;
            border-radius: 15px;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #1661FF;
            padding: 4px 8px;
            height: 28px;
        }
    }

    &>.shortInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px - 128px);
        gap: 30px;

        h1 {
            display: inline-block;

            svg {
                margin: 0 0 0 4px;
            }
        }

        .artistBlock {
            color: #868B96;
            font-family: var(--subfont);
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
        }

        .lowerBlock {
            display: flex;
            justify-content: space-between;

            .moreActionsWrapper {
                display: flex;
                gap: 16px;

                .block {
                    background-color: #E4E6EA;
                    color: #13141A;
                }

                &>a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    background: #E4E6EA;
                    border-radius: 8px;
                }
            }
        }
    }

    .followers {
        display: flex;
        gap: 4px;
        color: var(--foreground-day-secondary, #868B96);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        b {
            color: var(--foreground-day-main, #13141A);
            margin-right: 4px;
        }
    }

    .description {
        display: block;
        width: 100%;
        color: #13141A;
        //font-family: var(--subfont);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &Inner {
            max-height: 60px;
            overflow: hidden;

            &Wrapper {
                display: block;
            }
        }

        &Button {
            margin-top: 4px;
            margin-left: -16px;

            svg {
                margin: 0 0 0 8px;
                transform: rotate(-90deg);
            }
        }

        &.opened {
            .descriptionInner {
                max-height: unset;
            }

            .descriptionButton {
                svg {
                    transform: rotate(90deg);
                }
            }
        }

        &:not(.openable) {
            .descriptionButton {
                display: none;
            }
        }
    }

    .blockReason {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 24px;
        gap: 16px;
        background: #F8F8FA;
        border-radius: 8px;
        align-items: center;
        margin-top: 20px;

        div {
            display: flex;
            flex-direction: column;
            width: calc(100% - 8px - 72px);
            gap: 4px;

            p {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #13141A;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #868B96;
            }
        }
    }

    .smallStatistic {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: wrap;
        width: 100%;

        div {
            display: inherit;
            align-items: flex-start;
            flex-grow: 1;
            flex-basis: calc(50% - 4px);

            &>svg {
                margin-right: 8px;

                &+p {
                    width: calc(100% - 16px - 8px);
                }
            }

            span {
                margin-right: 4px;
                color: #13141A;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                opacity: 0.7;
            }

            p {
                color: #13141A;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;

                &.links {
                    display: inline-block;
                    gap: 4px;

                    span {
                        margin: 0 4px;
                    }

                    a {
                        color: var(--foreground-day-main, #13141A);
                    }
                }
            }
        }
    }
}