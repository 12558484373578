.input-mui {
  &__label {
    font-family: var(--subfont);
    font-size: 14px;
    transform: translate(14px, 17px) scale(1);

    &+.MuiInput-formControl {
      margin-top: 0;
    }

    &.input-mui__label--focused {
      color: var(--info);
    }

    &.input-mui__label--shrink {
      transform: translate(0, -6px) scale(0.75);
    }

    &.input-mui__label--error {
      color: var(--error);
    }
  }

  .MuiInputLabel-outlined.input-mui__label--shrink {
    transform: translate(13px, -6px) scale(0.75);
  }

  .MuiInputLabel-filled.input-mui__label--shrink {
    transform: translate(13px, 2px) scale(0.75);
  }

  &__root {
    padding-right: 0;
    max-width: 200px;
    font-family: var(--subfont);
    font-size: 14px;
    line-height: inherit;
    font-weight: 400;
    letter-spacing: normal;
    color: var(--dark);
    border-radius: 8px;
    background-color: var(--white);

    &--disabled {
      color: var(--gray);
    }

    &--multiline {
      padding: 0;
      max-width: unset;
    }

    &.MuiInputBase-fullWidth {
      max-width: none;
    }
  }

  &__input {
    box-sizing: border-box;
    //padding: 18px 40px 14px 14px;
    padding-left: 14px;
    height: 48px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #13141A;

    &:-webkit-autofill {
      background-color: #fff;
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: #fff;
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
    &:autofill{
      background-color: #fff;
      -webkit-box-shadow: 0 0 0 30px white inset;
    }

    &.MuiOutlinedInput-inputMarginDense {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 40px;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #13141A;
      opacity: 0.5;
    }
  }

  // Outline input
  .MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--info);
      }
    }

    &.input-mui__root--focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: var(--info);
      }
    }

    &.input-mui__root--disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--gray);
      }
    }

    &.input-mui__root-error {
      //margin-bottom: 24px;
      position: relative;

      .inline_error_text {
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #FB3254;
        display: inherit;

        .input-mui__error-icon {
          margin-right: 6px;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: var(--error);
      }
    }
  }

  .MuiOutlinedInput-input {
    &Multiline {
      padding: 14px 16px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--gray);
    transition: border-color 0.3s;
  }

  // Standard input & Filled input
  .MuiInput-underline,
  .MuiFilledInput-underline {
    &:hover:not(.input-mui__root--disabled):before {
      border-bottom: 1px solid var(--info);
    }

    &:before {
      border-color: var(--gray);
    }

    &:after {
      border-bottom: 1px solid var(--info);
    }
  }

  // Filled input
  .MuiFilledInput-root {
    background-color: var(--light);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__end-adornment {
    position: absolute;
    right: 16px;
    cursor: pointer;
  }

  &__helper-text {
    position: absolute;
    bottom: -20px;
    font-family: var(--subfont);

    &.Mui-error {
      color: var(--error);
    }
  }

  &__error-icon {
    //margin-right: 14px;
    cursor: pointer;
  }
}

.input_label_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &>span {
    font-family: var(--subfont);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #13141A;
    margin-bottom: 8px;
  }
}