@import 'src/assets/styles/mixins';

.audio {

  &Inner {
    .tabWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tabs {
        display: flex;

        & > div {
          margin: 0;
          display: flex;
          gap: 8px;

          & > button {
            padding: 6px 16px;
            background: #EBECEE;
            color: rgba(19, 20, 26, 0.7);
            border-radius: 16px;
            margin: inherit;

            &:global(.btnActive) {
              color: #FFFFFF;
              background: #296EFF;
            }

            &::after,
            &::before {
              display: none;
            }
          }
        }
      }
    }

    .rootMenu {
      display: flex;
      align-items: center;
      gap: 48px;
    }

    .inner-wrap {
      .filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;

        .search {
          width: 516px;

          & > div {
            max-width: unset;
          }
        }

        .moreFiltersButton {
          margin: 0 0 0 auto;
        }
      }
    }
  }
}

.dialog_wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.inputLabel {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.inline {
    flex-direction: row;
    align-items: center;
  }

  &.click {
    cursor: pointer;
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #13141A;
  }

  &__wrapper {
    display: flex;
    gap: 16px;

    .adornment {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #13141A;

      &__start {
        opacity: 0.5;

        & + input {
          padding: 16px 8px;
          text-align: right;
        }
      }

      &__end {
        width: 30px;
      }

      &__wide {
        width: 50px;
      }
    }
  }
}

.separator {
  background-color: #E4E6EA;
  width: 100%;
  height: 1px;
}

.button_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .clearFilters {
    padding-right: 0;
  }

  .cancel {
    background: #E4E6EA;
    color: #13141A;
  }
}

.nameWrapper {
  display: flex;
  flex: 1;
  gap: 8px;

  &.big {
    img {
      width: 56px;
      height: 56px;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
    }
  }

  img {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    object-fit: cover;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #13141A;
    }
  }
}

.albumInfo {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #868B96;

  .explicit {
    height: 12px;
    width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 2px;
    background-color: #868B96;
    color: #fff;
    font-size: 10px;
  }

  .trackPrice {
    color: #1661FF;
  }
}

.artistsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;

  .imagesWrapper {
    display: flex;

    img,
    .moreIndicator {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .artistNameWrapper {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 32px - 8px);
    width: calc(100% - 64px - 8px);

    p {
      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #13141A;
      }

      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #13141A;

      svg {
        margin: 0 4px -2px;
      }
    }

    span {
      font-family: var(--subfont);
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      color: #868B96;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.moreThenOne {
    .imagesWrapper {

      img,
      .moreIndicator {
        border: 2px solid #F8F8FA;
      }

      .moreIndicator {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        background: #E4E6EA;
      }

      & > *:not(:first-child) {
        margin-left: -8px;
      }
    }
  }

  &.inner {
    .imagesWrapper {

      img,
      .moreIndicator {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }

    .artistNameWrapper {
      flex-direction: row;
      gap: 4px;

      p,
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #13141A;
        font-family: var(--font);
      }
    }

    &.moreThenOne {
      .imagesWrapper {

        img,
        .moreIndicator {
          border: 1px solid #F1F2F3;
        }

        .moreIndicator {
          display: inline;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          padding: 2px;
        }

        & > *:not(:first-child) {
          margin-left: -4px;
        }
      }
    }
  }
}

.status {
  display: flex;
  padding: 8px 12px;
  border-radius: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  gap: 4px;
  width: max-content;
  align-items: center;

  &.active {
    background: rgba(46, 159, 105, 0.1);
    color: #2E9F69;
  }

  &.partiallyBlocked {
    background: rgba(248, 135, 31, 0.1);
    color: #F8871F;
  }

  &.blocked {
    background: rgba(251, 50, 84, 0.1);
    color: #FB3254;
  }
}

.moreActionsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;

  a {
    display: flex;
    align-items: center;
  }

  .moreActions {
    position: relative;

    &__btn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      width: 32px;
      padding: 8px;
      cursor: pointer;
      border-radius: 16px;

      span {
        display: block;
        background: #13141A;
        width: 3px;
        height: 3px;
        border-radius: 50%;
      }

      &.active {
        background: #FFFFFF;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: calc(100% + 8px);
      right: -8px;
      gap: 20px;
      padding: 20px;
      background: #FFFFFF;
      border: 1px solid #E4E6EA;
      box-shadow: 0px 16px 32px rgba(19, 20, 26, 0.05);
      border-radius: 8px;
      z-index: 300;
      width: max-content;

      .actionButtons {
        display: flex;
        gap: 8px;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #13141A;

        &.deleteAction {
          color: #FB3254;
        }
      }
    }
  }
}

.dialogTitle {
  margin-bottom: 16px;

  span {
    text-transform: capitalize;
  }
}

.dialogWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .desc {
    font-family: var(--subfont);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #868B96;

    span {
      color: #13141A;
    }

    &.small {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .blockReason {
    width: 100%;

    :global(.input-mui__root) {
      padding-right: 5px;
    }
  }

  .button_wrapper {
    justify-content: flex-start;
    gap: 16px;

    .dialogButtons {
      text-transform: capitalize;

      &:disabled {
        opacity: 0.5;
        color: var(--foreground-day-white, #FFF);
        background: var(--action-day-main, #296EFF);
      }
    }
  }
}

.detailsWrapper {
  display: flex;
  flex-direction: row;
  padding: 24px;
  gap: 24px;
  flex-wrap: wrap;
  background: #F8F8FA;
  border-radius: 8px;
  margin-top: 24px;

  &.tracks {
    gap: unset;

    h3 {
      margin-bottom: 24px;
    }
  }

  h3 {
    width: 100%;
  }

  &__item {
    width: 100%;
    display: flex;
    gap: 24px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #13141A;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #13141A;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 14px 16px;
        min-height: 48px;
        white-space: break-spaces;
        display: flex;
        gap: 12px;

        p {
          min-height: unset;
          padding: 0;
          width: max-content;
          display: flex;
          gap: 6px;
        }
      }

      &.sm {
        width: calc(33.333% - 12px);
      }

      &.md {
        width: calc(50% - 12px);
      }

      &.lg {
        width: calc(66.666% - 12px);
      }

      &.xl {
        width: 100%;
      }
    }
  }
}



:global(input#upload) {
  display: none;
}