.content {
    &Inner {
        .inner-wrap {
            .filters {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 24px;

                .search {
                    width: 516px;

                    &>div {
                        max-width: unset;
                    }
                }

                .moreFiltersButton {
                    margin: 0 0 0 auto;
                }

                .allAuto {
                    margin: auto 0 auto auto;
                    align-items: center;
                }

                .addNewButton {
                    display: flex;
                    align-items: center;
                    padding-right: 15px;
                    padding-left: 15px;

                    svg {
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}

.inner-wrap {
    width: 100%;
}

.tabWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tabs {
        display: flex;

        &>div {
            margin: 0;
            display: flex;
            gap: 8px;

            &>button {
                padding: 6px 16px;
                background: #EBECEE;
                color: rgba(19, 20, 26, 0.7);
                border-radius: 16px;
                margin: inherit;

                &:global(.btnActive) {
                    color: #FFFFFF;
                    background: #296EFF;
                }

                &::after,
                &::before {
                    display: none;
                }
            }
        }
    }
}

.autoIndicator {
    padding: 6px 16px 6px 12px;
    display: flex;
    align-items: center;
    width: min-content;
    gap: 4px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    &.true {
        color: var(--Additional-Green, #2E9F69);
        background: rgba(46, 159, 105, 0.10);
    }

    &.false {
        color: #F8871F;
        background: rgba(248, 135, 31, 0.10);
    }
}

.radioGroup {
    display: flex;

    &>label {
        margin: 0;
        gap: 4px;

        span {
            font-family: var(--font);
            color: var(--foreground-day-main, #13141A);
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
        }
    }
}

.nameWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
        width: 32px !important;
        height: 32px !important;
        border-radius: 50%;
    }

    span {
        &:global(.fi) {
            border: 1px solid var(--background-day-additional, #E4E6EA);
            width: 32px !important;
            height: 32px !important;
            border-radius: 50%;
        }
    }

    p {
        color: var(--foreground-day-main, #13141A);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }

    &>div:not(.info) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background: var(--background-day-additional, #E4E6EA);

        span {
            border: none !important;
        }
    }
}

.saveBtn {
    min-width: 92px;
}

.autoSwitcher {

    &Wrapper {
        display: flex;
        justify-content: space-between;
    }

    &Info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-width: 465px;

        p {
            color: var(--foreground-day-main, #13141A);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

        span {
            color: var(--foreground-day-secondary, #868B96);
            font-family: var(--subfont);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}

.tracks {
    &Wrapper {
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 24px 12px 9px !important;
    }

    &Heading {
        color: var(--foreground-day-main, #13141A);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding-left: 12px;

        span {
            color: var(--foreground-day-secondary, #868B96);
            font-weight: 500;
        }

        &Wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .copyBtn {
                margin-top: -6px;
                margin-bottom: -6px;
                min-width: 100px;
            }
        }

        &Buttons {
            display: flex;
        }
    }

    &List {
        display: flex;
        flex-direction: column;
        //gap: 20px;

        &Empty {
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 16px;

            padding: 72px 0;
            border-radius: 8px;
            border: 1px dashed var(--background-day-additional, #E4E6EA);

            &Wrapper {
                max-height: unset;
                height: auto;
                max-width: unset;
                padding: 0;
                margin: 10px 12px 15px;
                text-align: left;
                height: 218px;
            }

            &Image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 72px;
                height: 72px;
                border-radius: 72px;
                border-radius: 48px;
                background: var(--background-day-inputs, #FFF);
            }

            &Message {
                display: flex;
                flex-direction: column;
                gap: 4px;

                p {
                    color: var(--foreground-day-main, #13141A);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }

                span {
                    color: var(--foreground-day-secondary, #868B96);
                    font-family: var(--subfont);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;

                    span {
                        color: var(--action-day-secondary, #1661FF);
                        font-weight: 500;
                    }
                }
            }
        }
    }

    &Item {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 5px 12px;
        border-radius: 5px;
        background-color: #F8F8FA;

        &LeftSide {
            display: flex;
            gap: 12px;
            align-items: inherit;
            width: 50%;

            &>img {
                border-radius: 4px;
                width: 48px;
                height: 48px;
                object-fit: cover;
            }
        }

        &Index {
            width: 20px;
            height: 20px;
            color: var(--foreground-day-main, #13141A);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }

        &Info {
            width: calc(100% - 48px - 20px - 12px * 2);

            &.manual {
                width: calc(100% - 48px - 20px - 24px - 4px - 12px * 3);
            }

            p {
                color: var(--foreground-day-main, #13141A);
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                color: var(--foreground-day-secondary, #868B96);
                font-family: var(--subfont);
                font-size: 10px;
                font-weight: 500;
                line-height: 16px;
            }
        }

        &RightSide {
            display: flex;
            width: calc(50% - 16px);
            justify-content: space-between;
            align-items: center;

            svg {
                cursor: pointer;
            }
        }

        &AlbumName {
            color: var(--foreground-day-secondary, #868B96);
            font-family: var(--subfont);
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.availableTracksContent {
    padding-bottom: 0;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        background: linear-gradient(180deg, rgba(248, 248, 250, 0.00) 0%, #F8F8FA 100%);
    }
}

.addTraksDialogWrapper {
    gap: 24px;

    .saveBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        background: var(--background-day-inputs, #FFF);
        padding: 16px;
    }

    .searchInput {
        width: 100%;

        :global(.input-mui__root) {
            max-width: unset;
        }

        &Wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    .availableTracks {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 40px;
        max-height: 500px;
        width: calc(100% + 15px);
        padding-right: 15px;
        margin-right: -15px;

        &Item {
            width: 100%;
            display: flex;
            gap: 12px;
            cursor: pointer;

            img {
                width: 48px;
                height: 48px;
                border-radius: 4px;
            }

            .checkbox {
                margin: auto 0 auto auto;
            }

            .info {
                width: calc(100% - 48px - 24px - 12px - 12px);
            }
        }

        &Empty {
            display: flex;
            height: 400px;
            flex-direction: column;

            p {
                color: var(--foreground-day-main, #13141A);
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                opacity: 0.7;
            }

            span {
                color: var(--foreground-day-secondary, #868B96);
                font-family: var(--subfont);
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            color: var(--foreground-day-main, #13141A);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        span {
            color: var(--foreground-day-secondary, #868B96);
            font-family: var(--subfont);
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
        }

    }
}

.noAvailableCopy {
    border-radius: 8px;
    background: var(--background-day-inputs, #FFF);
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: -16px;

    p {
        color: var(--foreground-day-main, #13141A);
        font-family: var(--subfont);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

.copyTabsWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    p {
        color: var(--foreground-day-main, #13141A);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }

    .tabs>div>button {
        text-transform: capitalize;
    }
}

.addGenreWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .nameField {
        width: 100%;

        &>div {
            max-width: unset;
        }
    }
}