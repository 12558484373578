.errorText {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  font-size: 12px;
  line-height: 14px;
  color: var(--error);

  svg {
    width: 12px;
    height: 12px;
  }
}