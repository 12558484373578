@import 'reset';
@import 'vars';
@import 'helpers';
@import 'mixins';
@import '../fonts/Inter/stylesheet';
@import '../fonts/Gilroy/stylesheet';
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');

body {
  font-family: var(--font);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--primary);
  background-color: var(--auth-background);
  @include scrollbar(15px, var(--primary), var(--light), 5px, 2px);
}

a {
  color: var(--info);
}

.root {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.page-wrap {
  flex: 1;
  padding: 40px 0;

  &>.container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &>.inner-wrap {
      background: #F8F8FA;
      border-radius: 8px;
      padding: 24px;
    }
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  //max-width: var(--container);
  width: 100%;
}

.card-wrap {
  padding: 24px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

h1,
.h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #13141A;
}

h2,
.h2,
h3,
.h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #13141A;
}

h3,
.h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #13141A;
}

.backlink {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--info);

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 8px;
  }
}

// Table
.table {
  tr {
    border-bottom: 1px solid var(--light);
  }

  th {
    vertical-align: middle;
    padding: 8px 16px;
    height: 50px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: var(--info);
  }

  td {
    vertical-align: middle;
    padding: 8px 16px;
    height: 60px;
  }
}

.table-inner {
  display: flex;
  gap: 16px;

  &--right {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }
}

// Helper classes
.flex {
  display: flex;
  flex-wrap: wrap;

  &--column {
    flex-direction: column;
  }
}

.dots-end {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap {
  flex-wrap: nowrap;
}

.gap-25 {
  gap: 25px;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.full-width {
  width: 100%;
}

.good {
  color: var(--success);
}

.alert {
  color: var(--error);
}

.warning {
  color: var(--warning);
}

.bold {
  font-weight: 700;
}

.good-hover {
  transition: opacity ease 0.3s;

  @media (hover) {

    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }
}

.card-hover {
  cursor: pointer;
  transition: margin-top ease 0.3s, margin-bottom ease 0.3s;

  @media (hover) {

    &:hover,
    &:focus-visible {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
}

.no-select {
  user-select: none;
}

// Animations
.pulse {
  @media (hover) {

    &:hover,
    &:focus-visible {
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  0% {
    opacity: 1;
  }
}

// React Toastify
.Toastify__toast {
  font-family: inherit;

  &--error {
    background-color: var(--error);
  }

  &--success {
    background-color: var(--success);
  }
}

.pretty-scroll {
  overflow: auto;
  @include scrollbar(5px, #296eff90, transparent, 2px, 0px);

  &:not(.scroll) {
    &::-webkit-scrollbar-track {
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #296eff40 !important;
    }
  }
}

.cellText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #13141A;
}

#hiddenBackUrl {
  display: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;

  &.just-sb {
    justify-content: space-between;
  }

  &.just-c {
    justify-content: center;
  }

  &.al-c {
    align-items: center;
  }
}

em-emoji {
  font-size: 0;
  line-height: 0;

  img {
    display: block !important;
    width: 21px !important;
    height: 21px !important;
    max-width: unset !important;
    max-height: unset !important;
  }
}

hr {
  width: 100%;
  background: #E4E6EA;
  height: 1px;
}