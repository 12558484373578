.pagination {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  gap: 4px;

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &__item {
    min-width: 32px;
    height: 32px;
    border-radius: 2px;
    transition: background-color 0.3s;

    &:not(.selected) {

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    &.selected {
      border-radius: 8px;
      border: 1px solid var(--action-day-main, #296EFF);
    }
  }

  &__link {
    display: grid;
    place-items: center;
    padding: 4px;
    width: 100%;
    height: 100%;
    color: var(--foreground-day-main, #13141A);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &--active {
      color: #296EFF;
    }
  }

  &__ellipsis {
    display: flex;
    margin: auto 0 2px 0;
  }

  &__ellipsis-link {
    height: 24px;
    width: 24px;
    text-decoration: none;
    text-align: center;
    color: var(--foreground-day-main, #13141A);
  }

  &__prev-item,
  &__next-item {
    border-radius: 2px;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &.disabled {
      opacity: 0.5;

      a {
        cursor: not-allowed;
      }
    }
  }

  &__prev-link,
  &__next-link {
    display: flex;
  }

  &__icon {
    object-fit: contain;

    &.reverse {
      rotate: 180deg;
    }
  }
}