@import 'src/assets/styles/mixins';

.support {

  &Inner {
    .tabWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tabs {
        display: flex;

        &>div {
          margin: 0;
          display: flex;
          gap: 8px;

          &>button {
            padding: 6px 16px;
            background: #EBECEE;
            color: rgba(19, 20, 26, 0.7);
            border-radius: 16px;
            margin: inherit;

            &:global(.btnActive) {
              color: #FFFFFF;
              background: #296EFF;
            }

            &::after,
            &::before {
              display: none;
            }
          }
        }
      }
    }

    .inner-wrap {
      .filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;

        .search {
          width: 516px;

          &>div {
            max-width: unset;
          }
        }
      }
    }
  }
}

.noRightPadding {
  padding-right: 0;
}

.noLeftPadding {
  padding-left: 0;
}

.rowWithExpand,
.headerWithExpand {
  display: flex;
  flex-wrap: wrap;

  &>td {
    display: flex;
    align-items: center;
    height: unset;

    &:not(.expanderTD) {
      min-height: 60px;
    }
  }
}

.expander {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 0 auto;

  svg {
    border-radius: 50%;
    transition: transform 150ms;
    cursor: pointer;
  }

  &.active {
    svg {
      transform: rotate(180deg);
      background-color: #fff;
    }
  }

  &__header {
    display: none;
  }

  &__body {
    width: 100%;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 150ms;

    &>div {
      overflow: hidden;
    }

    &.active {
      grid-template-rows: 1fr;
    }
  }

  &__inner {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #13141A;
    }

    span {
      font-family: var(--subfont);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #13141A;
      white-space: break-spaces;
    }
  }
}

.expanderTD {
  height: unset;
  min-height: unset;
  padding: 0;

  :global(.skeleton-mui) {
    display: none;
  }
}

.modalLoad {
  margin-bottom: 20px;

  &__progress {
    place-self: center;

    svg {
      color: #296eff90;
    }
  }
}

.commentsDialog {
  max-height: calc(850px - 24px - 24px);
}

.commentsList {
  margin-top: 0 !important;
  gap: 20px;
  max-height: 650px;
}

.trackItem {
  border: none !important;
  padding: 0 !important;

  &__info {
    gap: 12px;

    &>span {
      margin-right: 8px;
    }
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px 6px 12px;
  text-transform: capitalize;

  &.new {
    background: rgba(41, 110, 255, 0.10);
    color: var(--action-day-main, #296EFF);
  }

  &.approved {
    background: rgba(46, 159, 105, 0.10);
    color: var(--Additional-Green, #2E9F69);
  }

  &.rejected {
    background: rgba(251, 50, 84, 0.10);
    color: var(--Additional-Red, #FB3254);
  }
}

.additionalButtons {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 0 auto;
}

.postButton {
  flex: 1;
  text-align: left;
}