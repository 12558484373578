.root {
  margin: 0 auto;
  padding: 80px;
  width: 100%;
  max-width: 480px;
  background: #F8F8FA;
  border: 1px solid #EBECEE;
  border-radius: 16px;
  text-align: center;

  .title {
    margin-bottom: 8px;
    color: #13141A;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .desc {
    margin-bottom: 48px;
    font: var(--subfont);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #868B96;
  }

  .input {
    margin-bottom: 24px;
  }

  .resPassWrap {
    position: relative;
  }

  .resPassLink {
    position: absolute;
    top: -18px;
    right: 0;
    font-size: 10px;
    line-height: 13px;
    font-weight: 600;
  }

  .roundedWrap {
    padding-top: 24px;
    padding-bottom: 32px;
    text-align: center;
    background: var(--light);
    border-radius: 8px;
  }

  .btn {
    margin-top: 24px;
  }

  .footer {
    margin-top: 104px;
    text-align: center;
  }

  .footerText {
    margin-right: 16px;
  }

  .link {
    font-weight: 700;
  }
}