.comingSoon {
    display: flex;
    flex: 1;

    &Inner {
        flex: 1;

        :global(.inner-wrap) {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
                margin-top: 24px;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                color: #868B96;
            }
        }
    }
}